import React, { useEffect } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import excel from "../assets/bilagsark.xlsx";

const Intro: React.FC = () => {
  useEffect(() => {
    document.title = "Longship Utlegg";
  });

  return (
    <Card data-testid="intro-card">
      <CardHeader title="Bilagsark" />
      <CardContent>
        <Typography paragraph>
          Hvis du har et kompleks eller langt utlegg å føre eller du har inntekt
          (enten alene eller sammen med utlegg) å sende inn så må du bruke
          bilagsark isteden.
        </Typography>

        <Typography paragraph>
          Last ned{" "}
          <a download="bilagsark.xlsx" href={excel}>
            bilagsark.xlsx
          </a>
        </Typography>

        <Typography paragraph>
          Når den er fyllt ut så sendes den til Britt - kontaktinfo ligger i
          selve excel filen.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Intro;
